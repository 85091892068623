import React from 'react'
import MapView from "./Pages/MapView/MapView"
import TopNav from "./Components/topnav/TopNav"
const App = () => {
  return (
    <div>
      <TopNav />
      <MapView />
    </div>
  )
}

export default App