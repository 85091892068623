import React, { useState, useEffect, useRef } from "react";
import useSwr from "swr";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./MapView.css";
import mapStyle__dark from "./MapStyle__dark"
import mapStyle__light from "./MapStyle__light"

const fetcher = (...args) => fetch(...args).then(response => response.json());
const Marker = ({ children }) => children;

var count = 0;
let preMarker;
export default function MapView() {
    let options = {
        styles: mapStyle__dark,
        disableDefaultUI: true,
        zoomControl: true
    }
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);
    const [deviceDetails, setDeviceDetials] = useState(null);

    const url =
        "https://api.enggenv.com/api/test_lsc_project";
    const { data, error } = useSwr(url, { fetcher });
    console.log(data);
    const devices = data && !error ? data.slice(0, 2000) : [];
    const points = devices.map((device, index) => ({
        type: "Feature",
        site: device.site,
        parameters: [...Object.entries(device).filter(item => item[0] !== "lat" && item[0] !== "site" && item[0] !== "lon" && item[0] !== "last_sync")],
        rewards: "$0",
        lut: device.last_sync,
        properties: { cluster: false, deviceKey: index, category: "IAQ" },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(device.lon),
                parseFloat(device.lat)
            ]
        }
    }));



    console.log(points)
    useEffect(() => {


        const timer = setInterval(() => {
            clickEventHandler(points[count]);
            count = count >= points.length ? 0 : count + 1;
            console.log(count)
        }, 5000)

        return () => {
            clearInterval(timer);
        };

    }, [deviceDetails])


    // }

    const clickEventHandler = (details) => {
        try {
            setDeviceDetials(details);
            // let preEle = document.getElementById(preMarker);
            console.log("####" + preMarker);
            if (preMarker) {
                console.log(preMarker)
                preMarker.style.filter = "drop-shadow(1px 1px 5px #0003) drop-shadow(-1px -1px 5px #0003)";
            }
            // preEle.style.width = "25px";
            let ele = document.getElementById(`marker${details.properties.deviceKey}`);
            ele.style.filter = "drop-shadow(2px 2px 1px #000) drop-shadow(-2px -2px 1px #000)"
            preMarker = document.getElementById(`marker${details.properties.deviceKey}`);
            // ele.style.width = "40px";
            let container = document.getElementById('deviceDetails');
            if (container.style.display === 'none') {
                container.style.display = 'block';
            }
        }
        catch (er) {
            console.log(er)
        }
    }


    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    console.log({ clusters, supercluster })

    const toggleSideMenuHandler = () => {
        let ele = document.getElementById('sidemenu').classList;
        console.log(ele)
        if (ele.value.includes('hide')) {
            ele.remove('hide');
        }
        else {
            ele.add('hide');
        }
    }

    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                defaultCenter={{ lat: 26.846, lng: 80.966 }}
                options={options}
                defaultZoom={12}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom, bounds }) => {
                    console.log(bounds)
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
            >
                {clusters.map(cluster => {
                    console.log(cluster)
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    console.log({ longitude, latitude })
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;

                    // if (isCluster) {
                    //   return (
                    //     <Marker
                    //       key={`cluster-${cluster.id}`}
                    //       lat={latitude}
                    //       lng={longitude}
                    //       onClick={clickEventHandler}
                    //     >
                    //       <div
                    //         className="cluster-marker"
                    //         style={{
                    //           width: `${10 + (pointCount / points.length) * 20}px`,
                    //           height: `${10 + (pointCount / points.length) * 20}px`
                    //         }}
                    //         onClick={() => {

                    //           const expansionZoom = Math.min(
                    //             supercluster.getClusterExpansionZoom(cluster.id),
                    //             20
                    //           );
                    //           mapRef.current.setZoom(expansionZoom);
                    //           mapRef.current.panTo({ lat: latitude, lng: longitude });
                    //         }}
                    //       >
                    //         {pointCount}
                    //       </div>
                    //     </Marker>
                    //   );
                    // }
                    // { console.log(1) }
                })}
                {points.map(device => {
                    return (

                        <Marker
                            key={`device-${device.properties.deviceKey}`}
                            lat={device.geometry.coordinates[0]}
                            lng={device.geometry.coordinates[1]}
                        >
                            <button className="crime-marker" onClick={clickEventHandler.bind(this, device)}>
                                <img src="/IAQ_marker3.png" id={`marker${device.properties.deviceKey}`} alt="device marker" />
                            </button>
                        </Marker>
                    );
                })}
            </GoogleMapReact>


            {deviceDetails && <div id="deviceDetails" style={{ display: "none" }} className="deviceDetailsContainer">
                <span className="close" onClick={() => { document.getElementById('deviceDetails').style.display = "none" }}>X</span>
                <h3 className="title">{deviceDetails && deviceDetails.site}</h3>
                {deviceDetails && <p style={{ fontSize: "0.7rem" }}>{deviceDetails.lut}</p>}
                <hr />
                <h2 className="_key" >Parameters</h2>
                {
                    deviceDetails && (deviceDetails.parameters).map(item => {
                        return <>
                            &emsp;<p className="_keySmall">{item[0].toUpperCase()}</p> : <p style={{ fontWeight: 'bold' }}>{item[1]}</p><br />
                        </>
                    })
                }

                {/* {deviceDetails && <div style={{ display: 'flex', alignItems: "center", columnGap: "0.2rem" }}>
                    <h3 className="_key">Rewards :</h3><h2 className="_value">{deviceDetails.rewards}</h2>
                </div>} */}
            </div>
            }
            <div className="bottomLeft">
                <div id="sidemenu" className="sidemenu">
                    <div className="sidemenu-item"><span className="bx bx-tv"> </span><label> Monitoring devices</label></div>
                    <div className="sidemenu-item"><span className="bx bx-network-chart"> </span><label> AQI Indices</label></div>
                    <div className="sidemenu-item"><span className="bx bx-bar-chart-square"> </span><label> Temperature Levels</label></div>
                </div>
                <img onClick={toggleSideMenuHandler} src="/globeGreenMobiliy.png" alt="Powered By E&amp;E Solutions" />
            </div>

        </div >
    );
}
