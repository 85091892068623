import React, { Component } from "react";
import "./topnav.css";
import { NavLink } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
// import notifications from "../../assets/JsonData/notification.json";
// import user_image from "../../assets/images/default-avatar.png";
// import user_menu from "../../assets/JsonData/user_menus.json";
import logo from "../../img/logo.png";

class TopNav extends Component {
  logout = (item) => {
    alert(item);
  };
  render() {
    // const curr_user = {
    //   display_name: JSON.parse(localStorage.getItem("devices")).user,
    //   image: user_image,
    // };

    // const renderNotificationItem = (item, index) => (
    //   <div className="notification-item" key={index}>
    //     <i className={item.icon}></i>
    //     <span>{item.content}</span>
    //   </div>
    // );

    const renderUserToggle = (user) => (
      <div className="topnav__right-user">
        <div className="topnav__right-user__image">
          <img src={user.image} alt="" />
        </div>
        <div className="topnav__right-user__name">{user.display_name}</div>
      </div>
    );

    const renderUserMenu = (item, index) => (
      <NavLink
        to={item.route}
        onClick={() => {
          if (item.content === "Logout") {
            localStorage.removeItem("token");
            // window.location.pathname("/");
            // sleep();
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }}
        key={index}
      >
        <div className="notification-item">
          <i className={item.icon}></i>
          <span>{item.content}</span>
        </div>
      </NavLink>
    );

    return (
      <div className="topnav">
        <div className="sidebar__logo">
          <img style={{ width: "150px" }} src={logo} alt="" />
        </div>
        {/* <div className="topnav__search">
          <input type="text" placeholder="Search here..." />
          <i className="bx bx-search" />
        </div> */}
        <div className="topnav__right">
          <div className="topnav__right-item">
            <button className="active">Map View</button>
          </div>
          <div className="topnav__right-item">
            <button>Services</button>
          </div>
          <div className="topnav__right-item">
            <button>Analytics</button>
          </div>
          <div className="topnav__right-item">
            <button>Manage</button>
          </div>
          <div className="topnav__right-item">
            <button>Profile</button>
          </div>
          <div className="topnav__right-item">
            <Dropdown icon="bx bx-sun" />
          </div>
          <div className="topnav__right-item">
            <Dropdown icon="bx bx-store" />
          </div>

          <div className="topnav__right-item">
            <Dropdown
            // customToggle={() => renderUserToggle(curr_user)}
            // contentData={user_menu}
            // renderItems={(item, index) => renderUserMenu(item, index)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TopNav;
